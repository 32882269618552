/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useTour } from '@reactour/tour';
import { TestType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { EditorPreview } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/editor-preview';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { TestingStageStatus } from '../../../../../models/entities/builder-course.models';
import { BrowserRoute } from '../../../../../routes/browser.routes';
import { useAppStore } from '../../../../../stores/context.store';
import { BackLink } from '../../../../components/back-link';
import { useStyles } from '../../../course/use-styles';
import { TestingCard } from '../../component/course-testing-card';
import { TestingResultModal } from '../../component/testing-result-modal/testing-result-modal';
import { demoAssessment } from '../../demoAssessment';
import { MODEL_MOCK_DATA, COURSE_TAGS_MOCK_DATA } from '../../mock-data';
import { INTRODUCTION_STEPS, MAIN_PAGE_CONTINUE_STEPS, MAIN_PAGE_FINISH_STEPS } from '../../steps';

export const DemoPage: FC = observer(() => {
  const classes = useStyles();
  const appStore = useAppStore();
  const { fieldsDisplay } = appStore.builderCourse;
  const timeToPassTest: number = 45;
  const { currentStep, setCurrentStep, setSteps } = useTour();
  const { courses, getStageStatus, resetDemoState } = demoAssessment;

  const [isAllowNextStep, setIsAllowNextStep] = useState<number | null>(0);
  const isAllowNext = typeof isAllowNextStep === 'number' && currentStep >= isAllowNextStep;

  const handleExitDemo = () => {
    resetDemoState();
    sessionStorage.clear();
  };

  useEffect(() => {
    setCurrentStep(0);

    if (sessionStorage.getItem('userTest')) {
      setSteps(MAIN_PAGE_FINISH_STEPS);
      setIsAllowNextStep(null);

      return;
    }
    if (getStageStatus === TestingStageStatus.proceed) {
      setSteps(MAIN_PAGE_CONTINUE_STEPS);
      setIsAllowNextStep(0);

      return;
    }

    setIsAllowNextStep(8);
    setSteps(INTRODUCTION_STEPS);
  }, []);

  return (
    <div>
      {courses.map((data) => {
        const expertiseLabel = MODEL_MOCK_DATA.test_options.test_type === TestType.sphere ? 'Сферы' : 'Компетенции';

        return (
          <div className={classes.root}>
            <BackLink
              to={BrowserRoute.courses}
              onClick={handleExitDemo}
              data-tour="finish-step"
              label="Назад к списку оценочных сессий"
            />
            <Paper>
              <div className={classes.orgName}>{data.organization.name}</div>
              <div className={classes.content}>
                <div className={classes.courseInfo}>
                  <div data-tour="introduction-step-1" className={classes.description}>
                    <p className={classes.qualification}>{data.qualification.value}</p>
                    <h1 className={classes.title}>{data.title}</h1>
                    {fieldsDisplay.show_description && <EditorPreview className={classes.desc} children={data.desc} />}
                  </div>
                  {(fieldsDisplay.show_academic_hours || fieldsDisplay.show_academic_hours || timeToPassTest) && (
                    <div className={classes.infoCardWrapper}>
                      <div data-tour="introduction-step-2" className={classes.infoCardWrapper}>
                        {fieldsDisplay.show_academic_hours && (
                          <div className={classes.infoCard}>
                            <div className={classes.secondaryText}>академических часов</div>
                            <div className={classes.infoTitle}>{data.academic.hours}</div>
                          </div>
                        )}
                        {fieldsDisplay.show_academic_months && (
                          <div className={classes.infoCard}>
                            <div className={classes.secondaryText}>месяцев обучения</div>
                            <div className={classes.infoTitle}>{data.academic.month}</div>
                          </div>
                        )}
                      </div>
                      {timeToPassTest && (
                        <div data-tour="introduction-step-3" className={classes.infoCard}>
                          <div className={classes.secondaryText}>примерное время прохождения</div>
                          <div className={cn(classes.infoTitle, classes.timeInfo)}>{timeToPassTest} мин</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <hr />
                <div data-tour="introduction-step-4" className={classes.courseBindings}>
                  <div className={classes.courseBinding}>
                    <div className={classes.secondaryText}>{expertiseLabel}</div>
                    <div className={classes.competencies}>
                      {COURSE_TAGS_MOCK_DATA.map((data3) => (
                        <Chip className={classes.bindingName} key={data3._id} label={data3.title} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Paper>
            <div className={classes.stages}>
              <div data-tour="introduction-step-5" className={cn(classes.stagesWrapper, 'scrollbar')}>
                {data.stages.map((demoStage) => (
                  <TestingCard
                    key={demoStage.order}
                    stage={demoStage as never}
                    isAllowNext={isAllowNext}
                  />
                ))}
              </div>
              <TestingResultModal stage={data.stages[0] as never} />
            </div>
          </div>
        );
      })}
    </div>
  );
});
