import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      wordBreak: 'break-word',
      gap: 24,
      width: '100%',
      borderRadius: 8,
      padding: {
        top: 0,
        bottom: 40,
        right: 40,
        left: 40,
      },
    },
    wrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
    },
    chip: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      width: '58%',
      paddingTop: '20px',
    },
    editor: {
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      userSelect: 'none',
      color: '#6F7C98',
      fontSize: 16,
      padding: '0px',
      lineHeight: '20px',
      marginTop: 16,
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
    },
    question: {
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      userSelect: 'none',
      color: theme.palette.gloomyBlue.main,
      font: {
        size: 18,
        weight: 500,
      },
      lineHeight: '24px',
    },
    type: {
      marginTop: 8,
    },
    image: {
      maxWidth: 350,
      objectFit: 'cover',
      width: '100%',
      cursor: 'pointer',
      marginTop: 8,
      borderRadius: 8,
    },
    answers: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 16,
    },
    label: {
      alignItems: 'center',
      '-webkit-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      userSelect: 'none',
    },
    buttonWrapper: {
      display: 'flex',
      gap: 16,
      marginTop: 8,
    },
    '@media (max-width: 1320px)': {
      root: {
        padding: {
          top: 20,
          bottom: 32,
          right: 32,
          left: 32,
        },
      },
    },
    '@media (max-width: 768px)': {
      root: {
        padding: 24,
      },
      buttonWrapper: {
        flexDirection: 'column-reverse',
        gap: 12,
      },
    },
  }),
  { name: 'Question' },
);
