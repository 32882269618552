import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      padding: {
        top: 36,
        bottom: 0,
        right: 48,
        left: 48,
      },
      width: '100%',
      height: '100%',
      flexGrow: 1,
    },
    proctoring: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    title: {
      color: theme.palette.gloomyBlue.main,
      margin: 0,
      backgroundColor: 'transparent',
      font: {
        size: 32,
        weight: 700,
      },
      lineHeight: '40px',
    },
    downloadLink: {
      display: 'flex',
      gap: 12,
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
    list: {
      position: 'relative',
      backgroundColor: theme.background.paper,
      width: '100%',
      padding: {
        top: 20,
        bottom: 40,
        right: 40,
        left: 40,
      },
      borderRadius: 16,
      maxHeight: 'calc(100vh - 180px)',
      overflow: 'auto',
    },
    spinner: {
      backgroundColor: '#FFFFFF80',
      zIndex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    table: {},
    tableHeaderCourseName: {
      minWidth: 250,
      width: '46%',
      padding: ['16px', '12px'],
      borderBottom: '1px solid #E4E7F2',
      display: 'flex',
      alignItems: 'center',
      gap: 12,
    },
    tableHeaderCols: {
      minWidth: 124,
      width: '18%',
      padding: ['16px', '11px'],
      borderBottom: '1px solid #E4E7F2',
    },
    tableBody: {
      position: 'relative',
    },
    download: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      justifySelf: 'flex-end',
      '& > svg': {
        color: theme.palette.primary.main,
      },
    },
    tableBodyCourseName: {
      minWidth: 250,
      width: '46%',
      padding: ['16px', '12px'],
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    tableBodyCols: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 4,
      padding: ['16px', '12px'],
      minWidth: 124,
      width: '18%',
    },
    mobileItem: {
      display: 'none',
      '& > hr': {
        margin: ['16px', 0],
      },
    },
    mobileItemLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 24,
      paddingBottom: 10,
    },
    mobileItemTitle: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
      color: theme.palette.gloomyBlue.main,
    },
    mobileItemIcon: {
      width: 22,
      height: 22,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    mobileItemInfo: {
      display: 'flex',
      gap: 6,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '20px',
      color: theme.palette.gloomyBlue.main,
    },
    secondaryText: {
      color: theme.palette.secondaryText.main,
    },
    '@media (max-width: 1320px)': {
      list: {
        maxHeight: 'calc(100vh - 233px)',
      },
    },
    '@media (max-width: 1024px)': {
      list: {
        '& > div:nth-last-child(2) > hr': {
          marginBottom: 0,
        },
      },
      table: {
        display: 'none !important',
      },
      mobileItem: {
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      },
    },
    '@media (max-width: 768px)': {
      proctoring: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 8,
      },
      downloadLink: {
        width: '100%',
        padding: '14px 12px !important',
        borderRadius: '8px !imporatant',
        backgroundColor: `${theme.palette.hover.main} !important`,
        textAlign: 'left',
      },
      list: {
        maxHeight: 'initial',
        padding: 24,
        '& > div:last-child': {
          alignItems: 'flex-start',
          flexDirection: 'column',
          '& > div': {
            width: '100%',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          },
        },
      },
    },
    '@media (max-width: 640px)': {
      root: {
        padding: {
          top: 34,
          bottom: 0,
          right: 0,
          left: 0,
        },
      },
      list: {
        '& > div:last-child > div > div:first-child': {
          flexBasis: '100%',
        },
      },
    },
  }),
  { name: 'Proctoring' },
);
