import { FC, ReactElement, useEffect, useMemo, useRef } from 'react';
import { QuestionType, TAnswerIds, UpdateMode } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { LOGGER_ACTION } from '../../../constants/actions';
import {
  ITestingQuestionCaseSequence,
  ITestingQuestionCaseTree,
  ITestingQuestionDto,
  ITestingQuestionOpen,
} from '../../../models/entities/testing.models';
import { useS3URL } from '../../../shared/use-s3-url';
import { useAppStore } from '../../../stores/context.store';
import { IUpdateAnswerOpts } from '../../content/course-stage/course-stage.models';
import { getQuestionAnswer } from '../answer-preview/utils';
import { QuestionFeedback } from '../question-feedback';
import { Question } from './component/Question/question';
import { QuestionCaseSequence } from './component/QuestionCaseSequence/questionCaseSequence';
import { QuestionCaseTree } from './component/QuestionCaseTree/question-case-tree';
import { QuestionOpen } from './component/QuestionOpen/question-open';
import { useStyles } from './use-styles';

type QuestionPreviewProps = {
  question: ITestingQuestionDto;
  index: number;
  onUpdate?: (opts: IUpdateAnswerOpts) => void;
  onImagePreview?: (src: string) => void;
  prevQuestion?(): void;
  nextQuestion?(): void;
  isOneByOneMode?: boolean;
  lastItemIndex?: number;
  isMatrix?: boolean | undefined;
};

export const QuestionPreview: FC<QuestionPreviewProps> = (props) => {
  const {
    index,
    question,
    lastItemIndex,
    isOneByOneMode,
    onImagePreview,
    prevQuestion,
    nextQuestion,
    onUpdate,
  } = props;

  const classes = useStyles();
  const { config, logger, users } = useAppStore();
  const { s3Token } = users.auth.data;

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const isAnswered = useMemo(() => Boolean(getQuestionAnswer(question).length), [question]);
  const { getS3URL } = useS3URL({ baseURL: config.imagesStorage, s3Token });

  const handleUpdate = (answer: string | TAnswerIds, mode?: UpdateMode): void => {
    if (onUpdate) {
      if (question.type === QuestionType.open) {
        onUpdate({ questionId: question['question-id'], type: question.type, userAnswer: answer as string, mode });
      }
      else {
        onUpdate({ questionId: question['question-id'], type: question.type, answerId: answer, mode });
      }
    }
  };

  const renderPictures = (pictures: string[], type: string): JSX.Element => {
    if (!pictures?.length) {
      return null;
    }

    const handleOnClick = (image: string): void => {
      if (onImagePreview) {
        onImagePreview(getS3URL(image));
        const action
          = type === 'question' ? LOGGER_ACTION.CLICK_OPEN_PICTURE_TASK : LOGGER_ACTION.CLICK_OPEN_PICTURE_ANSWER;

        logger.sendLog({
          action,
          request: {},
        });
      }
    };

    return (
      <>
        {pictures.map((image, idx) => (
          <img
            key={idx}
            className={classes.image}
            alt="Изображение"
            src={getS3URL(image)}
            onClick={(): void => handleOnClick(image)}
          />
        ))}
      </>
    );
  };

  const getQuestionByType = (type: QuestionType): ReactElement => {
    if (type === QuestionType.matrix) {
      return (
        <QuestionCaseSequence
          question={question as ITestingQuestionCaseSequence}
          renderPictures={renderPictures}
          handleUpdate={handleUpdate}
        />
      );
    }
    if (type === QuestionType.open) {
      return (
        <QuestionOpen
          question={question as ITestingQuestionOpen}
          renderPictures={renderPictures}
          handleUpdate={handleUpdate}
        />
      );
    }
    if (type === QuestionType.tree) {
      return (
        <QuestionCaseTree
          question={question as ITestingQuestionCaseTree}
          renderPictures={renderPictures}
          handleUpdate={handleUpdate}
        />
      );
    }
    return <Question question={question} renderPictures={renderPictures} handleUpdate={handleUpdate} />;
  };

  // Отключение копирование текста в вопросе. Отключен контекстное меню, клавиши ctrl+c/x, выделение и перетаскивание.
  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      const wrapper = wrapperRef.current;

      wrapper.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      });

      wrapper.addEventListener('keydown', (e) => {
        if (e.ctrlKey && (e.key === 'c' || e.key === 'x')) {
          e.preventDefault();
        }
      });

      wrapper.addEventListener('selectstart', (e) => {
        e.preventDefault();
      });

      wrapper.addEventListener('dragstart', (e) => {
        e.preventDefault();
      });

      return () => {
        wrapper.removeEventListener('contextmenu', (e) => {
          e.preventDefault();
        });

        wrapper.removeEventListener('keydown', (e) => {
          if (e.ctrlKey && (e.key === 'c' || e.key === 'x')) {
            e.preventDefault();
          }
        });

        wrapper.removeEventListener('selectstart', (e) => {
          e.preventDefault();
        });

        wrapper.removeEventListener('dragstart', (e) => {
          e.preventDefault();
        });
      };
    }

    return null;
  }, []);

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <Paper id={question['question-id']} className={classes.root}>
        <div className={classes.chip} data-question-tour={`step-${question.type}`}>
          <Chip color={isAnswered ? 'info' : undefined} label={`Вопрос #${index + 1}`} />
        </div>
        <div>{getQuestionByType(question.type)}</div>
        {isOneByOneMode && (
          <div className={classes.buttonWrapper}>
            <Button size="sm" variant="outlined" onClick={prevQuestion} disabled={index === 0}>
              Назад
            </Button>
            <Button
              id="test-step-4"
              size="sm"
              variant="gradient"
              onClick={nextQuestion}
              disabled={index === lastItemIndex}
            >
              Далее
            </Button>
          </div>
        )}
      </Paper>
      <QuestionFeedback questionId={question['question-id']} />
    </div>
  );
};
