import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/button';
import { Checkbox } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/checkbox';
import { Modal, ModalFooter, ModalHeader } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import cn from 'classnames';
import { useAppStore } from '../../../stores/context.store';
import { AlertCircle } from '../icons/alert-circle';
import { CloseV2Icon } from '../icons/close-v2';
import { useStyles } from './use-styles';

interface ICausesFieldsOption {
  label: string;
  value: string;
  checked: boolean;
}

const OTHER_FIELD_VALUE = 'other';

const CAUSES_FIELDS: ICausesFieldsOption[] = [
  { label: 'Опечатка в задании', value: 'typo', checked: false },
  { label: 'Другое', value: OTHER_FIELD_VALUE, checked: false },
];

interface IQuestionFeedbackProps {
  questionId: string;
}

export const QuestionFeedback: FC<IQuestionFeedbackProps> = ({ questionId }) => {
  const classes = useStyles();
  const modal = useModalState();
  const { id: courseId } = useParams();
  const { questionsFeedback } = useAppStore();
  const { loading, sendQuestionFeedback } = questionsFeedback;

  const [causes, setCauses] = useState(CAUSES_FIELDS);
  const [comment, setComment] = useState('');
  const [remainingChars, setRemainingChars] = useState(800);

  const location = useLocation();
  const isDemoMode = location.pathname.includes('demo-assessment');

  const isVisible = useMemo(() => {
    const otherCause = causes.find((cause) => cause.value === OTHER_FIELD_VALUE);

    return otherCause.checked;
  }, [causes]);

  const isSubmitDisabled = useMemo(() => {
    if (loading) {
      return true;
    }

    const hasCauseChecked = causes.some((cause) => cause.checked);

    if (!hasCauseChecked) {
      return true;
    }

    return isVisible && comment.length === 0;
  }, [loading, isVisible, comment, causes]);

  const handleCheckboxChange = (value: string, checked: boolean): void => {
    setCauses((prev) => prev.map((cause) => {
      if (cause.value === value) {
        return {
          ...cause,
          checked,
        };
      }

      return cause;
    }));
  };

  const handleCommentChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const newComment = event.target.value;
    setComment(newComment);
    setRemainingChars(800 - newComment.length);
  };

  const handleCancelFeedback = (): void => {
    modal.close();
    setCauses(CAUSES_FIELDS);
    setComment('');
    setRemainingChars(800);
  };

  const handleSendFeedback = (): void => {
    const text = causes.reduce((acc, cause) => {
      const { checked, value, label } = cause;
      if (!checked) {
        return acc;
      }

      const result = value === OTHER_FIELD_VALUE ? comment : label;

      return acc ? `${acc}, ${result}` : result;
    }, '');

    sendQuestionFeedback({
      question_id: questionId,
      course_id: courseId,
      text,
    });

    handleCancelFeedback();
  };

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <AlertCircle />
      </div>
      <p className={classes.text} onClick={!isDemoMode ? modal.open : undefined}>
        Если вы нашли неточности в задании, напишите нам
      </p>
      <Button className={classes.button} onClick={modal.open} variant="outlined" disabled={isDemoMode}>
        Написать в поддержку
      </Button>

      <Modal {...modal.props} className={classes.modal}>
        <ModalHeader>
          <CloseV2Icon className={classes.closeIcon} onClick={modal.close} />
          <header className={classes.header}>
            <h6 className={classes.title}>Неточности в задании</h6>
            <p className={classes.subTitle}>
              Пожалуйста, отметьте пункты, в которых, по вашему мнению, была допущена ошибка.
            </p>
          </header>
        </ModalHeader>
        <div className={classes.causes}>
          {causes.map(({ value, checked, label }) => (
            <label
              className={classes.checkbox}
              htmlFor={value}
              onClick={(): void => handleCheckboxChange(value, !checked)}
              key={value}
            >
              <Checkbox name={value} checked={checked} id={value} />
              {label}
            </label>
          ))}
          {isVisible && (
            <div className={classes.textareaContainer}>
              <textarea
                className={classes.textarea}
                placeholder="Опишите неточность в задании"
                rows={4}
                value={comment}
                onChange={handleCommentChange}
                maxLength={800}
              />
              <div className={classes.charCounter}>
                Осталось символов: {remainingChars}
              </div>
            </div>
          )}
        </div>
        <ModalFooter className={classes.modalFooter}>
          <Button
            className={cn(classes.actionButton, classes.cancel)}
            onClick={handleCancelFeedback}
            size="sm"
            variantStyle="standard"
          >
            Отмена
          </Button>
          <Button className={classes.actionButton} disabled={isSubmitDisabled} size="sm" onClick={handleSendFeedback}>
            Отправить
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
