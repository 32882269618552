import { IWarningModal } from '../models/entities/stages.models';

export const secondsInMinute = 60;

const SEC_MS = 1000;
export const MINUTE_MS = SEC_MS * secondsInMinute;
export const DECLENSION_OPTIONS_SEC = ['секунду', 'секунды', 'секунд'];
export const DECLENSION_OPTIONS_MIN = ['минута', 'минуты', 'минут'];

export const MODAL_OPTIONS: Record<number, IWarningModal> = {
  30: {
    message: 'Нажмите на "Завершить оценку!", чтобы засчитать попытку, или все ваши ответы будут удалены!',
    btnLabel: 'Завершить оценку!',
    action: 'finish',
  },
  300: {
    message:
        'Обязательно нажмите на кнопку "Завершить", чтобы сохранить ваши результаты. Если этого не сделать, все ответы будут удалены.',
    btnLabel: 'ОК',
    action: 'close',
  },
  600: {
    message:
        'Обязательно нажмите на кнопку "Завершить", чтобы сохранить ваши результаты. Если этого не сделать, все ответы будут удалены.',
    btnLabel: 'ОК',
    action: 'close',
  },
};
