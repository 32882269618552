import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import { Wrapper } from '@unione-pro/unione.commons.wrapper';
import { BrowserRoute } from '../../routes/browser.routes';
import { Layout } from '../components/layout';
import { Page } from '../components/page';
import { Access } from '../content/access';
import { Auth } from '../content/auth';
import { CertificatePage } from '../content/certificates';
import { CoursePage } from '../content/course';
import { CourseStagePage } from '../content/course-stage';
import { CourseStageAnswersPage } from '../content/course-stage-answers';
import { Courses } from '../content/courses';
import { DemoAssessmentStagePage } from '../content/demo-assessment/pages/demo-assessment-stage';
import { DemoPage } from '../content/demo-assessment/pages/demo-assessment-view';
import { DemoAssessmentStageAnswersPage } from '../content/demo-assessment/pages/demo-course-stage-answers';
import { Tour } from '../content/demo-assessment/Tour';
import { NotFound } from '../content/not-found';
import { Proctoring } from '../content/proctoring';
import { ProctoringPage } from '../content/proctoring-page';
import { RedirectToCourse } from '../content/redirect-to-course';
import { YandexMetrika } from '../content/yandex-metrika';


export const appRoutesDefinition: RouteObject[] = [
  {
    path: '/',
    element: (
      <Wrapper>
        <YandexMetrika>
          <Auth>
            <Layout>
              <Access>
                <Outlet />
              </Access>
            </Layout>
          </Auth>
        </YandexMetrika>
      </Wrapper>
    ),

    children: [
      { index: true, element: <Navigate to={BrowserRoute.courses} /> },
      {
        path: '*',
        element: <NotFound />,
      },
      {
        path: BrowserRoute.courses,
        element: (
          <Courses />
        ),
      },
      {
        path: BrowserRoute.courseOldRoute(),
        element: <RedirectToCourse />,
      },
      {
        path: BrowserRoute.course(),
        element: (
          <Page>
            <Proctoring>
              <CoursePage />
            </Proctoring>
          </Page>
        ),
      },
      {
        path: BrowserRoute.courseStage(),
        element: (
          <Page>
            <Proctoring>
              <CourseStagePage />
            </Proctoring>
          </Page>
        ),
      },
      {
        path: BrowserRoute.courseStageAnswers(),
        element: (
          <Page>
            <CourseStageAnswersPage />
          </Page>
        ),
      },
      {
        path: BrowserRoute.proctoring,
        element: (
          <Page>
            <ProctoringPage />
          </Page>
        ),
      },
      {
        path: BrowserRoute.certificates,
        element: (
          <Page>
            <CertificatePage />
          </Page>
        ),
      },
      {
        path: BrowserRoute.demoAssessment,
        element: (
          <Page>
            <Tour />
          </Page>
        ),
        children: [
          {
            index: true,
            element: <DemoPage />,
          },
          {
            path: BrowserRoute.demoAssessmentStage(),
            element: (
              <Proctoring>
                <DemoAssessmentStagePage />
              </Proctoring>
            ),
          },
          {
            path: BrowserRoute.demoAssessmentStageAnswers(),
            element: (
              <DemoAssessmentStageAnswersPage />
            ),
          },
        ],
      },
    ],
  },
];
