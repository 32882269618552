import { QuestionType } from '@unione-pro/unione.assmnt.sdk.webapp';

export const engineErrorMessages: { [key: number]: string } = {
  101: 'Сессия не найдена',
  102: 'Данные результатов пользователя не существует',
  103: 'Данные результатов оценочной сессии не существует',
  104: 'Оценочная сессия никогда не была начата или уже закончена',
  105: 'Оценочную сессию не завершали, или возможно даже и не начинали',
  106: 'Оценочная сессия не была начата',
  107: 'Ошибка начала сессии',
  108: 'Ошибка обновления сессии (записи ответов)',
  109: 'Ошибка завершения сессии',
  110: 'К сожалению, попытки на прохождение оценки закончились',
  111: 'К сожалению, время на оценку закончилось',
  112: 'Системная ошибка',
  114: 'Слишком частые запросы',
  122: 'Оценочная сессия уже пройдена',
  123: 'Недостаточно вопросов для генерации сессии',
  124: 'У вас нет доступа к оценочной сессии',
  125: 'Что-то с сервером',
  126: 'К сожалению, оценочная сессия завершилась',
  129: 'Системная ошибка парсинга тела запроса для вебсокета',
  130: 'Превышено количество соединений',
  131: 'Открыто тестирование этого пользователя по другому этапу',
  132: 'Тестирование уже открыто на другом устройстве/вкладке',
  141: 'В профиле не заполен СНИЛС',
  144: 'Номер телефона пользователя не подтвержден в профиле.',
};

export interface ITestingTest<TDate = Date> {
  attempt: number;
  'attempt-time': TDate;
  'course-id': string;
  'course-stage': number;
  id: string;
  'test-duration': number;
  'user-id': string;
  timeLeft?: number;

  questions: ITestingQuestion[];
}

export type TestingTestDtoTypes = {
  [key in QuestionType]?: boolean;
};

export interface ITestingTestDto<TDate = Date> extends Omit<ITestingTest<TDate>, 'questions'> {
  types?: TestingTestDtoTypes;
  questions: ITestingQuestionDto[];
}

export interface ITestingQuestion {
  'competence-id': string;
  pictures: string[];
  question: string;
  'question-id': string;
  type: {
    one?: ITestingAnswersOne;
    plural?: ITestingAnswersPlural;
    case?: ITestingAnswersPlural;
  };
}

export type ITestingQuestionDto =
  | ITestingQuestionOne
  | ITestingQuestionPlural
  | ITestingQuestionCase
  | ITestingQuestionCaseSequence
  | ITestingQuestionCaseTree
  | ITestingQuestionOpen;

interface ITestingQuestionOne extends Omit<ITestingQuestion, 'type'> {
  type: QuestionType.one;
  level: string;
  description?: string;
  answers: ITestingAnswer[];
  'user-answer-id'?: string;
  'correct-answer-id': string;
  steps?: undefined;
}

interface ITestingQuestionPlural extends Omit<ITestingQuestion, 'type'> {
  type: QuestionType.plural;
  level: string;
  description?: string;
  answers: ITestingAnswer[];
  'user-answer-id'?: string[];
  'correct-answer-id': string[];
  steps?: undefined;
}

interface ITestingQuestionCase extends Omit<ITestingQuestion, 'type'> {
  type: QuestionType.case;
  level: string;
  description?: string;
  answers: ITestingAnswer[];
  'user-answer-id'?: string;
  'correct-answer-id': string;
  steps?: undefined;
}

export interface ITestingQuestionCaseSequence extends Omit<ITestingQuestion, 'type'> {
  type: QuestionType.matrix;
  level: string;
  description?: string;
  answers: ITestingAnswer[];
  'user-answer-id'?: Record<number, string>;
  'correct-answer-id': string;
  steps?: undefined;
}

export interface ITestingQuestionCaseTree extends Omit<ITestingQuestion, 'type'> {
  type: QuestionType.tree;
  title?: string;
  description?: string;
  answers: undefined;
  steps: ITestingQuestionCaseTreeStep[];
  'user-answer-id'?: Record<number, string>;
}

export interface ITestingQuestionOpen extends Omit<ITestingQuestion, 'type'> {
  type: QuestionType.open;
  description: string;
  title?: string;
  'user-answer'?: string;
  'user-answer-id': undefined;
  answers: undefined;
  steps?: undefined;
}

export interface ITestingQuestionCaseTreeQuestion {
  id: string;
  prev_id: string;
  question: string;
  answer: string;
  level: string;
}

export interface ITestingQuestionCaseTreeStep {
  id: string;
  order: number;
  questions: ITestingQuestionCaseTreeQuestion[];
}

interface ITestingAnswersOne {
  'correct-answer-id': string;
  level: string;
  'user-answer-id'?: string;
  answers: ITestingAnswer[];
  steps?: undefined;
}

interface ITestingAnswersPlural {
  'correct-answer-id': string[];
  level: string;
  'user-answer-id'?: string[];
  answers: ITestingAnswer[];
  description?: string;
  steps?: undefined;
}

export interface ITestingAnswer {
  answer?: string;
  'answer-id'?: string;
  pictures?: string[];
  'user-answer'?: string;
}

export interface ITestInfo<TDate = Date> {
  attempt: number;
  'attempt-time': TDate;
  'course-id': string;
  'course-stage': number;
  'test-duration': number;
  'user-id': string;
}
