import { FC, Fragment } from 'react';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import cn from 'classnames';
import { IExpertiseResult } from '../../../../../models/stores/testing.store';
import { useResultGridStyles } from '../../../../components/testing-result-modal/use-styles';

interface ITestingResultModalGridProps {
  data: IExpertiseResult[];
  columns: string[];
  level: string;
}

export const ResultGrid: FC<ITestingResultModalGridProps> = (props) => {
  const classes = useResultGridStyles({ count: props.columns.length - 1 });
  return (
    <div className={cn(classes.root, 'scrollbox')}>
      <div className={classes.container}>
        {props.columns.map((label, index) => (
          <div key={index} className={cn(classes.secondaryText, classes.cell)}>
            {label}
          </div>
        ))}
        {props.data.map((result) => (
          <Fragment key={result.id}>
            <div style={{ gridRow: `span ${result.stages.length}` }} className={cn(classes.cell, classes.title)}>
              {result.expertise}
            </div>
            {result.stages.map((stage) => (
              <Fragment key={stage.id}>
                <div className={cn(classes.cell, classes.borderTop)}>
                  <Chip label={stage.name} customColor={stage.color} />
                </div>
                <div className={cn(classes.cell, classes.borderTop)}>{stage.levelLabel}</div>
                {Boolean(stage.description) && (
                  <div className={cn(classes.cell, classes.borderTop)}>{stage.description}</div>
                )}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </div>
      <div className={classes.mobile}>
        {props.data.map((result) => (
          <div key={result.id}>
            <h2 className={classes.mobileTitle}>{result.expertise}</h2>
            {result.stages.map((stage) => (
              <div key={stage.id} className={classes.mobileStage}>
                <p className={classes.secondaryText}>Оценка:</p>
                <p className={classes.stage} style={{ background: stage.color }}>
                  {stage.name}
                </p>
                <p className={classes.secondaryText}>Уровень:</p>
                <p>{props.level}</p>
                {Boolean(stage.description) && (
                  <>
                    <p className={classes.secondaryText}>Описание:</p>
                    <p>{stage.description}</p>
                  </>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
