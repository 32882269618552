/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip, toast } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { STAGE_STATUSES, ACTIVE_COURSES } from '../../../../../constants/builder-course.constants';
import { TestingStageStatus } from '../../../../../models/entities/builder-course.models';
import { BrowserRoute } from '../../../../../routes/browser.routes';
import { getErrorMessage } from '../../../../../shared/error-message';
import { useTimer } from '../../../../../shared/use-timer';
import { useAppStore } from '../../../../../stores/context.store';
import { ProctoringIcon } from '../../../../components/icons/proctoring';
import { demoAssessment } from '../../demoAssessment';
import { ITestingCardProps } from './course-testing-card.models';
import { useStyles } from './use-styles';

export const TestingCard: FC<ITestingCardProps> = observer(({ stage, isAllowNext }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { builderCourse, serverTime } = useAppStore();
  const { enableOnlyQuestions } = demoAssessment;

  const customStage = useMemo(() => {
    if (sessionStorage.getItem('userTest')) {
      const test = stage.order === 1 ? STAGE_STATUSES.completed : STAGE_STATUSES.notPassed;
      return { ...stage, ...test };
    }

    return stage;
  }, [stage]);

  const handleClickButton = (): void => {
    try {
      if (stage.status === TestingStageStatus.proceed) {
        enableOnlyQuestions();
      }
      if (isAllowNext) {
        const demoPath = sessionStorage.getItem('userTest')
          ? BrowserRoute.demoAssessmentStageAnswers(stage.href)
          : BrowserRoute.demoAssessmentStage(stage.href);

        navigate(demoPath);
      }
      if (stage.status === TestingStageStatus.completed) {
        navigate(stage.href);
      }
    }
    catch (error) {
      toast({
        type: 'error',
        text: getErrorMessage(error),
      });
    }
  };

  const stageEndDate = useMemo((): number => {
    const currentDate = dayjs(serverTime);
    const targetDate = dayjs(stage.end_date);
    const differenceInMilliseconds = targetDate.diff(currentDate);
    const differenceInSeconds = dayjs.duration(differenceInMilliseconds).asSeconds();

    if (stage.timeLeft > differenceInSeconds) {
      return differenceInSeconds - 10;
    }

    return stage.timeLeft;
  }, [stage.timeLeft, serverTime]);

  const { timer } = useTimer({
    timeLeft: stageEndDate,
    finish: () => {
      localStorage.removeItem(ACTIVE_COURSES);
      builderCourse.refetch();
    },
  });

  return (
    <Paper className={classes.stage}>
      <div className={classes.stageHeader}>
        <Chip label={stage.name} customColor={stage.color} />
        {stage.is_procto && (
          <Tooltip content="В оценке применяется прокторинг" className={classes.procto}>
            <ProctoringIcon data-tour="introduction-step-7" className={classes.secondaryText} />
          </Tooltip>
        )}
      </div>
      <div className={cn(classes.secondaryText, classes.stageBody)}>
        <p data-tour="introduction-step-6">{customStage.interval}</p>
        {timer ? <p>Осталось времени {timer}</p> : <p data-tour="introduction-step-6-2">{customStage.statusTitle}</p>}
      </div>
      <Button
        data-tour="introduction-step-8"
        onClick={handleClickButton}
        size={customStage.isCompleted ? 'none' : 'sm'}
        variantStyle={customStage.isCompleted ? 'standard' : undefined}
        disabled={customStage.disabled}
        className={classes.btn}
      >
        {customStage.btnLabel}
      </Button>
    </Paper>
  );
});
