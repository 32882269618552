import { FC, SVGProps } from 'react';

export const BigTimerIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="64" height="64" rx="12" fill="#0071CE" />
    <path
      d="M35.2838 35.3491C35.8077 35.5237 36.3741 35.2406 36.5487 34.7166C36.7234 34.1927 36.4402 33.6264 35.9163 33.4517L35.2838 35.3491ZM32 33.2004H31C31 33.6308 31.2755 34.013 31.6838 34.1491L32 33.2004ZM33 28.1826C33 27.6304 32.5523 27.1826 32 27.1826C31.4477 27.1826 31 27.6304 31 28.1826H33ZM35.9163 33.4517L32.3163 32.2517L31.6838 34.1491L35.2838 35.3491L35.9163 33.4517ZM33 33.2004V28.1826H31V33.2004H33ZM40.6 32.0004C40.6 36.75 36.7497 40.6004 32 40.6004V42.6004C37.8542 42.6004 42.6 37.8546 42.6 32.0004H40.6ZM32 40.6004C27.2504 40.6004 23.4 36.75 23.4 32.0004H21.4C21.4 37.8546 26.1458 42.6004 32 42.6004V40.6004ZM23.4 32.0004C23.4 27.2507 27.2504 23.4004 32 23.4004V21.4004C26.1458 21.4004 21.4 26.1462 21.4 32.0004H23.4ZM32 23.4004C36.7497 23.4004 40.6 27.2507 40.6 32.0004H42.6C42.6 26.1462 37.8542 21.4004 32 21.4004V23.4004Z"
      fill="white"
    />
  </svg>
);
