import { StepType } from '@reactour/tour';
import { QuestionType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { badgeArrowStyles } from './badgeArrowStyles';

const QUESTION_ANSWER_SELECT = (questionType: QuestionType, activeNextStep: boolean = true): StepType[] => [
  {
    selector: `[data-tour="test-step-${questionType}"]`,
    position: (position) => {
      if (window.innerHeight <= 840) {
        return [position.x - 25 - position.width, window.innerHeight - position.height - 20];
      }

      return [position.x - 25 - position.width, position.y - 10];
    },
    styles: {
      badge: () => badgeArrowStyles.left,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-10 font-semibold">Выберите ответ(ы)</div>
      </div>
    ),
  },
  activeNextStep && {
    selector: '#test-step-4',
    position: 'top',
    styles: {
      badge: () => badgeArrowStyles.top,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-10 font-semibold">После ответа на вопрос нажмите «Далее»</div>
      </div>
    ),
  },
];

export const INTRODUCTION_STEPS: StepType[] = [
  {
    selector: '[data-tour="introduction-step-1"]',
    position: 'bottom',
    styles: {
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Название оценочной сессии, квалификация и аннотация к оценочной сессии
        </div>
        <div className="text-slate-600">
          Здесь вы увидите название квалификации, название оценочной сессии, которую будете проходить, и ее краткое
          описание
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-2"]',
    position: (position) => [position.x - 25 - position.width, position.y - 10],
    styles: {
      badge: () => badgeArrowStyles.left,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Трудоемкость и срок реализации</div>
        <div className="text-slate-600">
          Здесь обозначаются сроки реализации и трудоемкость программы, по которой вы проходите оценочную сессию. Если
          оценка не является частью образовательной программы, этого блока не будет в оценочной сессии
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-3"]',
    position: (position) => {
      if (window.innerWidth <= 1490) {
        return [position.x - 25 - position.width, position.y + 12 - position.width];
      }

      return 'left';
    },
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.leftBottom;
        }
        if (window.innerHeight <= 804) {
          return badgeArrowStyles.secondLeftBottom;
        }

        return badgeArrowStyles.left;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Блок с данными о времени прохождения оценочной сессии
        </div>
        <div className="text-slate-600">
          Перед началом сверьтесь со временем, необходимым на оценку. Если сейчас вам неудобно проходить оценку,
          выберите другое удобное время
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-4"]',
    position: 'bottom',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }
        if (window.innerHeight <= 868) {
          return badgeArrowStyles.top;
        }

        return badgeArrowStyles.bottom;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Блок с компетенциями</div>
        <div className="text-slate-600">Здесь указан перечень компетенций, по которым проводится оценочная сессия</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="never"]',
    position: 'center',
    padding: { mask: 0 },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Начало оценки</div>
        <div className="text-slate-600">Далее будет показано, как начать оценочную сессию</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-5"]',
    position: 'top',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }

        return badgeArrowStyles.top;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Этапы оценки</div>
        <div className="text-slate-600">
          У оценочной сессии может быть один или несколько этапов. Количество, обозначенное в сессии, не будет
          изменяться
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-6"]',
    position: 'top',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }

        return badgeArrowStyles.top;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Даты доступности оценочной сессии</div>
        <div className="text-slate-600">
          У каждого этапа обозначен временной период, в который вам будет доступна оценочная сессия
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-6-2"]',
    position: 'top',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }

        return badgeArrowStyles.top;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Попытки</div>
        <div className="text-slate-600">
          Количество попыток старта оценки. Попытки доступны пока вы не завершите оценку. После пройти оценку заново не будет возможности
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-7"]',
    position: (position) => [position.x - 25 - position.width, position.top - position.height + 75],
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.leftBottom;
        }

        return badgeArrowStyles.leftBottom;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Значок прокторинга</div>
        <div className="text-slate-600">
          Если в этапе оценочной сессии используется прокторинг, вы увидите значок в виде силуэта человека в рамке
        </div>
        <div className="text-xl text-slate-800 mb-2 font-semibold">Как пройти оценку с прокторингом?</div>
        <div className="text-slate-600">
          Зайдите в свой профиль и во вкладке «Документы» сделайте три эталонных фото, а в личном кабинете распечатайте
          согласие на обработку персональных данных, подпишите собственноручно и передайте это согласие организатору
          оценки
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-8"]',
    position: 'bottom',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }
        if (window.innerHeight <= 880) {
          return badgeArrowStyles.top;
        }
        if (window.innerHeight <= 906) {
          return badgeArrowStyles.rightUpperTop;
        }

        return badgeArrowStyles.bottom;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Чтобы начать оценку, нажмите кнопку «Начать оценку»
        </div>
      </div>
    ),
  },
];

export const TEST_STEPS: StepType[] = [
  {
    selector: '[data-tour="test-step-1"]',
    position: 'left',
    styles: {
      badge: () => badgeArrowStyles.left,
    },

    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Вид отображения вопросов: списком</div>
        <div className="text-slate-600">
          В оценочной сессии вопросы могут предъявляться списком — все вопросы на одном экране, пролистываются
          скроллингом
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-2"]',
    position: 'left',
    styles: {
      badge: () => badgeArrowStyles.left,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Вид отображения: по одному</div>
        <div className="text-slate-600">Или по одному — каждый вопрос выводится отдельно</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-3"]',
    position: 'bottom',
    styles: {
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Переход к нужному вопросу через меню наверху</div>
        <div className="text-slate-600">
          Во время прохождения оценочной сессии вы можете перемещаться от вопроса к вопросу, кликая на номер вопроса
        </div>
      </div>
    ),
  },
  {
    selector: '#test-step-4',
    position: 'top',
    styles: {
      badge: () => badgeArrowStyles.top,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Переход к следующему вопросу через кнопку «Далее»
        </div>
        <div className="text-slate-600">
          Или нажимая на кнопку «Далее», если вопросы выводятся по одному. Отвеченные вопросы подчеркиваются снизу
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-5"]',
    position: 'left',
    styles: {
      badge: () => badgeArrowStyles.left,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Прогресс бар</div>
        <div className="text-slate-600">Каждый ответ заполняет шкалу прогресса</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-6"]',
    position: (position) => {
      if (window.innerHeight <= 900) {
        return [position.x - 25 - position.width, position.top - position.height + 61];
      }

      return 'left';
    },
    styles: {
      badge: () => {
        if (window.innerHeight <= 900) {
          return badgeArrowStyles.leftBottom;
        }

        return badgeArrowStyles.left;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Таймер</div>
        <div className="text-slate-600">Отсчитывает время, которое у вас осталось на завершение оценки</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-9"]',
    position: (position) => {
      if (window.innerHeight <= 750) {
        return [position.x - 25 - position.width, position.top - position.height + 85];
      }

      return 'left';
    },
    styles: {
      badge: () => {
        if (window.innerHeight <= 750) {
          return badgeArrowStyles.leftBottom;
        }

        return badgeArrowStyles.left;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Закрыть</div>
        <div className="text-slate-600">
          Позволяет вернуться к главному экрану оценки. Внимание: Не останавливает таймер и не завершает оценку
        </div>
      </div>
    ),
  },
];

export const QUESTION_STEPS: Record<string, StepType[]> = {
  [QuestionType.one]: [
    {
      padding: { mask: 100000 },
      position: () => [0, 200],
      selector: `[data-question-tour="step-${QuestionType.one}"]`,
      action: (elem) => {
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      },
      content: (
        <div>
          <div className="text-xl text-slate-900 mb-2 font-semibold">Тип ответа на вопрос: один из списка</div>
          <div className="text-slate-600">
            В оценочной сессии есть несколько вариантов заданий. Первый из них — тест с одним ответом. Выберите один из
            списка
          </div>
        </div>
      ),
    },
    ...QUESTION_ANSWER_SELECT(QuestionType.one),
  ],
  [QuestionType.plural]: [
    {
      padding: { mask: 100000 },
      position: () => [0, 200],
      selector: `[data-question-tour="step-${QuestionType.plural}"]`,
      action: (elem) => {
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      },
      content: (
        <div>
          <div className="text-xl text-slate-900 mb-2 font-semibold">Тип ответа на вопрос: несколько из списка</div>
          <div className="text-slate-600">
            Следующий тип заданий — тест с несколькими верными ответами. Выберите все верные ответы
          </div>
        </div>
      ),
    },
    ...QUESTION_ANSWER_SELECT(QuestionType.plural),
  ],
  [QuestionType.case]: [
    {
      padding: { mask: 100000 },
      position: () => [0, 200],
      selector: `[data-question-tour="step-${QuestionType.case}"]`,
      action: (elem) => {
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      },
      content: (
        <div>
          <div className="text-xl text-slate-900 mb-2 font-semibold">Кейс-тест</div>
          <div className="text-slate-600">
            В оценочной сессии есть и практические задания. Одно из них — кейс-тест. Ознакомьтесь с условиями задачи и
            выберите один самый подходящий вариант ответа
          </div>
        </div>
      ),
    },
    ...QUESTION_ANSWER_SELECT(QuestionType.case),
  ],
  [QuestionType.matrix]: [
    {
      padding: { mask: 100000 },
      position: () => [0, 200],
      selector: `[data-question-tour="step-${QuestionType.matrix}"]`,
      action: (elem) => {
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      },
      content: (
        <div>
          <div className="text-xl text-slate-900 mb-2 font-semibold">Кейс-последовательность</div>
          <div className="text-slate-600">
            Следующий тип кейсов — это кейс-последовательность, он предполагает установление правильной очередности
            действий, операций или явлений. Прочитайте задания и начните устанавливать последовательность. Если вы
            поняли, что устанавливаете не верную последовательность, сбросьте ответ и начните заново
          </div>
        </div>
      ),
    },
    ...QUESTION_ANSWER_SELECT(QuestionType.matrix),
  ],
  [QuestionType.tree]: [
    {
      padding: { mask: 100000 },
      position: () => [0, 200],
      selector: `[data-question-tour="step-${QuestionType.tree}"]`,
      action: (elem) => {
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      },
      content: (
        <div>
          <div className="text-xl text-slate-900 mb-2 font-semibold">Кейс: дерево вопросов</div>
          <div className="text-slate-600">
            Следующий тип кейса — дерево вопросов. Он основан на правильном выборе вопросов, а не ответов, как в
            классических заданиях. Прочтите описание и задайте дополнительные вопросы для решения этого кейса
          </div>
        </div>
      ),
    },
    ...QUESTION_ANSWER_SELECT(QuestionType.tree, false),
  ],
};

export const TEST_FINISH_STEPS: StepType[] = [
  {
    selector: '[data-tour="test-step-10"]',
    position: (position) => {
      if (window.innerHeight <= 900) {
        return [position.x - 25 - position.width, position.y + 77 - position.width];
      }

      return 'left';
    },
    styles: {
      badge: () => {
        if (window.innerHeight <= 900) {
          return badgeArrowStyles.leftBottom;
        }

        return badgeArrowStyles.left;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Кнопка «Завершить»</div>
        <div className="text-slate-600">После того как все задания решены, обязательно нажмите кнопку «Завершить»</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-11"]',
    padding: { popover: 30, mask: 10000 },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Нажмите завершить, чтобы перейти к следующему этапу демо-тестирования
        </div>
      </div>
    ),
  },
];

export const ANSWERS_PAGE_STEPS: StepType[] = [
  {
    selector: '[data-tour="answer-step-1"]',
    position: 'left',
    styles: {
      popover: (props, state) => ({
        ...state,
        ...props,
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        maxWidth: '290px',
      }),
      badge: () => badgeArrowStyles.left,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Раздел «Ответы»</div>
        <div className="text-slate-600">
          После того как вы завершите оценочную сессию, вы увидите свои ответы на вопросы без отметки о правильности.{' '}
        </div>
        <div className="text-slate-600">
          Чтобы узнать результаты оценки, нажмите на кнопку «Назад к оценочной сессии»
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="answer-step-2"]',
    position: 'bottom',
    styles: {
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-slate-600">Нажмите на кнопку «Назад к оценочной сессии»</div>
      </div>
    ),
  },
];

export const MAIN_PAGE_FINISH_STEPS: StepType[] = [
  {
    selector: '[data-tour="main-page-answer-step-1"]',
    position: 'top',
    styles: {
      badge: () => badgeArrowStyles.top,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Кнопка «Смотреть результаты»</div>
        <div className="text-slate-600">
          Результаты оценки рассчитываются сразу и узнать их можно, нажав на кнопку «Смотреть результаты»
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour-disabled="main-page-answer-step-1"]',
    position: 'top',
    styles: {
      badge: () => badgeArrowStyles.top,
    },
    content: (
      <div>
        <div className="text-slate-600">Нажмите на кнопку «Смотреть результаты»</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="main-page-answer-step-2"]',
    position: (position) => [position.x - 25 - position.width, (position.windowHeight - position.height) / 2],
    styles: {
      badge: () => badgeArrowStyles.leftCenter,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Раздел «Компетенции»</div>
        <div className="text-slate-600">
          Результаты показываются двумя способами. Первый - таблица.
          <div className="my-2">В ней обозначается:</div>
          <ul className="list-disc list-inside marker:text-primary-darkBlue">
            <li>название компетенции</li>
            <li>название этапа оценки (столбец «Оценка»)</li>
            <li>выявленный в ходе оценки уровень сформированности компетенции (столбец «Уровень»)</li>
            <li>
              интерпретация выявленного уровня, т.е. обозначение, каким образом компетенция проявляется (столбец
              «описание»)
            </li>
          </ul>
          Если в оценочной сессии было несколько компетенций, такой набор информации будет представлен для каждой
          компетенции
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="main-page-answer-step-2"]',
    position: 'left',
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Кнопка «Диаграмма»</div>
        <div className="text-slate-600">
          Чтобы увидеть свои результаты в виде диаграммы, нажмите на кнопку «Диаграмма»
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="main-page-answer-step-3"]',
    position: 'bottom',
    styles: {
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-slate-600">Нажмите на кнопку «Диаграмма»</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="main-page-answer-step-2"]',
    position: (position) => [position.x - 25 - position.width, position.y],
    styles: {
      badge: () => badgeArrowStyles.leftCenter,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Всплывающий блок с целевой компетенцией и достигнутой
        </div>
        <ul className="text-slate-600">
          <li>При наведении на один из лучей диаграммы во всплывающем окне появится:</li>
          <li>- название компетенции</li>
          <li>- выявленный в ходе оценки уровень сформированности компетенции</li>
          <li>- уровень данной компетенции, который был обозначен как целевой, т.е. рекомендуемый к достижению</li>
        </ul>
      </div>
    ),
  },
  {
    selector: '[data-tour="main-page-answer-step-2"]',
    position: (position) => [position.right + 25, position.y],
    styles: {
      popover: (props, state) => ({
        ...state,
        ...props,
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        maxWidth: '250px',
      }),
      badge: () => badgeArrowStyles.rightSlightlyLower,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Кнопка закрытия окна</div>
        <div className="text-slate-600">Ознакомившись с результатами, закройте окно</div>
      </div>
    ),
  },
];

export const MAIN_PAGE_CONTINUE_STEPS: StepType[] = [
  {
    selector: '[data-tour="introduction-step-6"]',
    position: 'top',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }

        return badgeArrowStyles.top;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Таймер оставшегося времени</div>
        <div className="text-slate-600">
          Отсчитывает время до завершения оценки. Если оценка не завершена до конца времени таймера - попытка сгорает
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-8"]',
    position: 'bottom',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }
        if (window.innerHeight <= 880) {
          return badgeArrowStyles.top;
        }
        if (window.innerHeight <= 906) {
          return badgeArrowStyles.rightUpperTop;
        }

        return badgeArrowStyles.bottom;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Чтобы продолжить оценку, нажмите кнопку «Продолжить»
        </div>
      </div>
    ),
  },
];

export const FINISH_STEP: StepType[] = [
  {
    selector: '[data-tour="finish-step"]',
    position: (position) => [position.left - 9, position.bottom + 20],
    styles: {
      popover: (props, state) => ({
        ...state,
        ...props,
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        maxWidth: '360px',
      }),
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Поздравляем!</div>
        <div className="text-slate-600">
          Вы завершили демонстрационную оценочную сессию. Нажмите на кнопку “Назад к списку оценочных сессий“, чтобы
          применить полученные знания в реальных оценочных сессиях.
        </div>
      </div>
    ),
  },
];
