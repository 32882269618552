import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PopoverContentProps } from '@reactour/tour';
import { QuestionType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { demoAssessment } from './demoAssessment';

const disabledActions = [
  '[data-tour="main-page-answer-step-3"]',
  '[data-tour="answer-step-2"]',
  '[data-tour="test-step-9"]',
  '[data-tour="test-step-11"]',
  '[data-tour="introduction-step-8"]',
  '[data-tour-disabled="main-page-answer-step-1"]',
];

const disabledNextBeforeAnswered: Record<string, QuestionType> = {
  '[data-tour="test-step-one"]': QuestionType.one,
  '[data-tour="test-step-plural"]': QuestionType.plural,
  '[data-tour="test-step-case"]': QuestionType.case,
  '[data-tour="test-step-matrix"]': QuestionType.matrix,
  '[data-tour="test-step-open"]': QuestionType.open,
  '[data-tour="test-step-tree"]': QuestionType.tree,
};

export const TourNavigation: PopoverContentProps['components']['Navigation'] = observer(
  ({ currentStep, steps, setCurrentStep }) => {
    const { getUserQuestions, setByOneOutput, resetDemoState } = demoAssessment;
    const navigate = useNavigate();

    const currentSelector = steps[currentStep].selector.toString();

    const questionTourType = disabledNextBeforeAnswered[currentSelector];
    const userAnswer = getUserQuestions?.find(({ type }) => type === questionTourType)?.['user-answer-id'];
    const isDisabledNext = questionTourType && !userAnswer;

    const handleNext = () => {
      setCurrentStep(currentStep + 1);
      steps[currentStep].selector === '[data-tour="test-step-1"]' && setByOneOutput();
    };

    const handlePrev = () => {
      setCurrentStep(currentStep - 1);
      currentSelector === '[data-tour="test-step-2"]' && setByOneOutput(false);
    };

    const handleExitDemo = () => {
      resetDemoState();
      sessionStorage.clear();
      navigate(BrowserRoute.courses);
    };

    useEffect(() => {
      document.documentElement.style.overflowY = 'hidden';

      return () => {
        document.documentElement.style.overflowY = 'auto';
      };
    }, []);

    if (disabledActions.includes(currentSelector as string)) {
      return null;
    }

    return (
      <div className="flex flex-col gap-2 mt-5">
        <Button disabled={isDisabledNext} onClick={handleNext} size="sm">
          Далее
        </Button>
        {
          currentStep !== 0 && (<Button
            style={{
              backgroundColor: '#EFF8FE',
              color: '#0071CE',
            }}
            onClick={handlePrev}
            disabled={currentStep === 0}
            size="sm"
            variantStyle="standard"
          >
            Назад
          </Button>)
        }
        <Button size="sm" variant="white" onClick={handleExitDemo}>
          Выйти из демоверсии
        </Button>
      </div>
    );
  },
);
