import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  () => ({
    root: {
      width: 'fit-content',
      maxWidth: '100%',
      overflow: 'hidden',
      borderRadius: 8,
    },
    linkWrapper: {
      display: 'flex',
      width: '100%',
      overflowX: 'scroll',
      '& > a': {
        '&:first-child': {
          borderRadius: '8px 0 0 8px',
        },
        '&:last-child': {
          borderRadius: '0 8px 8px 0',
        },
      },
    },
    label: {
      width: 48,
      height: 48,
      cursor: 'pointer',
      borderBottomWidth: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
      transitionDuration: '150ms',
      '&:hover': {
        backgroundColor: '#EFF6FF',
      },
    },
    '@media (max-width: 640px)': {
      linkWrapper: {
        width: 'calc(100% - 48px)',
      },
    },
  }),
  { name: 'Question' },
);
