import { CSSProperties, FC } from 'react';
import { useTour } from '@reactour/tour';

export const BadgeWithArrow: FC = () => {
  const { steps, currentStep } = useTour(); // Извлекаем текущий шаг из контекста

  const { styles } = steps[currentStep];

  const styleArrow = { ...styles?.badge?.({}) };

  const arrowStyles: CSSProperties = {
    position: 'absolute',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    ...styleArrow,
  };

  return <div style={arrowStyles} />;
};
