import { FC, Fragment, ReactNode, useMemo, useState } from 'react';
import { TAnswerIds, UpdateMode } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Checkbox } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/checkbox';
import { EditorPreview } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/editor-preview';
import {
  ITestingQuestionCaseTree,
  ITestingQuestionCaseTreeQuestion,
  ITestingQuestionCaseTreeStep,
} from '../../../../../models/entities/testing.models';
import { useStyles } from './use-styles';

interface IQuestionCaseTreeProps {
  question: ITestingQuestionCaseTree;
  renderPictures(pictures: string[], type: string): JSX.Element;
  handleUpdate(answerId: TAnswerIds, mode?: UpdateMode): void;
}

export const QuestionCaseTree: FC<IQuestionCaseTreeProps> = ({ question, renderPictures, handleUpdate }) => {
  const { 'user-answer-id': answersId = {}, steps, question: title } = question;
  const classes = useStyles();
  const [answers, setAnswers] = useState<Record<number, string>>(answersId);
  const [currentOrder, setCurrentOrder] = useState<number>(Object.keys(answersId).length + 1);
  const currentSteps = useMemo(() => steps.filter((step) => step.order <= currentOrder), [currentOrder, steps]);

  const choseQuestion = (order: number, id: string): void => {
    const answersArr = Object.values(answers);
    if (answersArr.length >= order || answersArr.includes(id)) {
      answersArr.splice(order - 1);
    }
    if (answers[order] !== id) {
      answersArr.push(id);
    }
    const newAnswers = answersArr.reduce((acc, currentValue, index) => {
      acc[index + 1] = currentValue;
      return acc;
    }, {});
    setAnswers(newAnswers);
    setCurrentOrder(answersArr.length + 1);
    handleUpdate(newAnswers);
  };

  const getQuestionItems = (step: ITestingQuestionCaseTreeStep): ITestingQuestionCaseTreeQuestion[] => (
    step.questions.filter((questionItem) => questionItem.prev_id === answers[step.order - 1] || step.order === 1));

  const getAnswer = (order: number): ReactNode => {
    const currentStep = steps.find((step) => step.order === order);
    const currentQuestion = currentStep?.questions.find((questionItem) => questionItem.id === answers[order]);
    return currentQuestion?.answer;
  };

  return (
    <div className={classes.root}>
      <div>
        <h2 className={classes.title}>{title}</h2>

        {renderPictures(question.pictures, 'question')}
        <EditorPreview children={question.description} />
      </div>
      <hr />
      <h2 className={classes.title}>Выберите вопросы, которые могут Вам определить архитектуру решения:</h2>
      <div className={classes.questions} data-tour={`test-step-${question.type}`}>
        {currentSteps.map((step) => (
          <Fragment>
            <div className={classes.question}>
              {getQuestionItems(step).map((questionItem) => (
                <div className={classes.questionItem} onClick={(): void => choseQuestion(step.order, questionItem.id)}>
                  <Checkbox checked={questionItem.id === answers[step.order]} />
                  <p>{questionItem.question}</p>
                </div>
              ))}
            </div>
            {!!answers[step.order] && <div className={classes.answer}>{getAnswer(step.order)}</div>}
            <hr />
          </Fragment>
        ))}
      </div>
    </div>
  );
};
