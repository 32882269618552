import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(
  (theme) => ({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      padding: '36px 48px',
      wordBreak: 'break-word',
    },
    orgName: {
      padding: '8px 40px',
      backgroundColor: '#FBFDFF',
      color: theme.palette.secondaryText.main,
      borderBottom: '1px solid #E4E7F2',
      borderRadius: '8px 8px 0 0',
      fontSize: 16,
    },
    content: {
      padding: 40,
      backgroundColor: 'white',
      borderRadius: '0 0 8px 8px',
      display: 'flex',
      flexDirection: 'column',
      gap: 28,
      boxShadow: '0px 4px 8px #ECEDF080',
      '& > hr': {
        backgroundColor: '#E4E7F2',
      },
    },
    editor: {
      padding: 0,
      color: '#6F7C98',
    },
    courseInfo: {
      display: 'flex',
      gap: 32,
      paddingBottom: 20,
    },
    description: {
      width: 'min-content',
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
      flexGrow: 1,
      marginRight: 50,
      height: '100%',
    },
    title: {
      color: '#1E253B',
      display: '-webkit-box',
      boxOrient: 'vertical',
      wordBreak: 'break-word',
      lineClamp: 2,
      overflow: 'hidden',
      font: {
        family: theme.fontFamily.monserrat,
        size: 24,
        weight: 600,
      },
      lineHeight: '28px',
    },
    secondaryText: {
      color: theme.palette.secondaryText.main,
    },
    desc: {
      padding: 0,
      color: theme.palette.secondaryText.main,
    },
    infoTitle: {
      color: theme.palette.primary.main,
      font: {
        family: theme.fontFamily.monserrat,
        size: 32,
        weight: 700,
      },
      lineHeight: '40px',
    },
    timeInfo: {
      color: '#F7B055',
    },
    infoCard: {
      flexGrow: 1,
      maxWidth: 200,
      borderRadius: 12,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      background: '#F8F9FF',
      minHeight: 130,
      marginBottom: 'auto',
    },
    competencies: {
      display: 'flex',
      marginTop: 16,
      rowGap: 16,
      columnGap: 12,
      flexWrap: 'wrap',
    },
    bindingName: {
      whiteSpace: 'pre-line',
    },
    stages: {
      display: 'flex',
      gap: 32,
    },
    stagesWrapper: {
      display: 'flex',
      gap: 32,
      flexGrow: 1,
      maxWidth: 'calc(100% - 165px)',
      overflow: 'auto',
      paddingBottom: 4,
    },
    infoCardWrapper: {
      gap: 16,
      display: 'flex',
    },
    courseBindings: {
      width: '100%',
      display: 'flex',
      gap: 20,
    },
    courseBinding: {
      width: '100%',
    },
    loaderWrapper: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 16,
      rowGap: 16,
    },
    loaderLabel: {
      maxWidth: 320,
      textAlign: 'center',
      color: theme.palette.primary.main,
      font: {
        size: 20,
        weight: 600,
      },
      lineHeight: '28px',
    },
    qualification: {
      color: theme.palette.secondaryText.main,
      fontWeight: 500,
      lineHeight: '20px',
    },
    '@media (max-width: 1490px)': {
      stages: {
        gap: 20,
        flexWrap: 'wrap',
      },
      infoCardWrapper: {
        flexDirection: 'column',
      },
      stagesWrapper: {
        maxWidth: '100%',
        gap: 24,
      },
    },
    '@media (max-width: 1320px)': {
      root: {
        padding: 28,
      },
      courseInfo: {
        gap: 0,
      },
      infoCardWrapper: {
        gap: 32,
      },
      infoCard: {
        minWidth: 'initial',
      },
      secondaryText: {
        fontSize: 14,
      },
      orgName: {
        padding: '12px 32px',
      },
      content: {
        padding: 32,
      },
    },
    '@media (max-width: 1120px)': {
      orgName: {
        padding: '16px 24px',
      },
      content: {
        padding: 24,
        '& > hr': {
          display: 'none',
        },
      },
      courseInfo: {
        padding: 0,
        gap: 24,
        flexDirection: 'column',
      },
      infoCardWrapper: {
        flexDirection: 'column',
      },
      description: {
        margin: 0,
        width: '100%',
      },
      title: {
        fontSize: 20,
        lineHeight: '24px',
      },
      infoCard: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        gap: 8,
      },
    },
    '@media (max-width: 640px)': {
      root: {
        padding: 0,
        marginTop: 34,
      },
      stages: {
        flexDirection: 'column',
      },
      stagesWrapper: {
        flexDirection: 'column',
      },
      competencies: {
        flexDirection: 'column',
      },
    },
  }),
  { name: 'Course' },
);
