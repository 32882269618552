import { ChangeEventHandler, FC, ReactElement, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Landing, Modal, ModalFooter, ModalHeader } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { CloseIcon } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/icons/close';
import { useDebounceEffect } from 'ahooks';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { LS_SHOW_DEMO_MODAL } from '../../../constants/demo-ass';
import { HttpCodes } from '../../../models/requests/http.models';
import { ICourse } from '../../../models/stores/builder.course.store';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useAppStore } from '../../../stores/context.store';
import { Block } from '../../components/block';
import { EmptyCard } from '../../components/empty-card';
import { SearchIcon } from '../../components/icons/search';
import { Page } from '../../components/page';
import { useStyles } from './use-styles';

export const Courses: FC = observer(() => {
  const { testing, builderCourse } = useAppStore();
  const classes = useStyles();
  const navigate = useNavigate();
  const courses = testing.courses.courses;
  const [searchValue, setSearchValue] = useState<string>('');
  const [currentCourses, setCurrentCourses] = useState<ICourse<string>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // TO-DO при включении демо-версии убрать `false && `
  const [isOpenTourModal, setIsOpenTourModal] = useState(false && !Boolean(localStorage.getItem(LS_SHOW_DEMO_MODAL)));


  const handleCloseTourModal = () => {
    setIsOpenTourModal(false);
    localStorage.setItem(LS_SHOW_DEMO_MODAL, 'true');
  };

  const handleStartTour = () => {
    handleCloseTourModal();
    navigate(BrowserRoute.demoAssessment);
    sessionStorage.removeItem('userTest');
  };

  useEffect(() => {
    builderCourse.reset();
    setCurrentCourses(courses.data);
    setIsLoading(courses.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses.data, courses.loading]);

  useDebounceEffect(
    () => {
      setCurrentCourses(() => {
        const needle = searchValue.trim().toLowerCase();
        if (needle && courses.data) {
          return courses.data.filter((course) => course.title.toLowerCase().match(needle));
        }
        return courses.data;
      });
    },
    [searchValue],
    { wait: 300 },
  );

  const changeSearchValueHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
  };

  if (courses.errorCode === HttpCodes.notFound) {
    return <Landing />;
  }

  return (
    <Page>
      <Block loading={isLoading} error={courses.error} data={currentCourses}>
        {(courseList): ReactElement => (
          <div className={classes.root}>
            <header className={classes.header}>
              <h1 className={classes.title}>Оценочные сессии</h1>
              <div className={classes.info}>
                <p className={classes.count}>Всего доступных оценочных сессий: {courses.data.length}</p>
                {/* <Button
                  onClick={() => setIsOpenTourModal(true)}
                  size="sm"
                  variant="gradient"
                >
                  Демоверсия оценки
                </Button> */}
              </div>
            </header>
            {courses.data.length > 0 ? (
              <div className={classes.courses}>
                <label className={classes.search}>
                  <input
                    className={classes.searchInput}
                    value={searchValue}
                    onChange={changeSearchValueHandler}
                    placeholder="Поиск по названию"
                  />
                  <SearchIcon />
                </label>

                <div className={classes.coursesList}>
                  {courseList.length > 0 ? (
                    courseList.map((course) => {
                      const { color, label, interval } = course.currentStage;

                      return (
                        <Link to={BrowserRoute.course(course._id)} key={course._id} className={classes.course}>
                          <h4 className={classes.courseTitle}>{course.title}</h4>
                          <div className={classes.courseInfo}>
                            <strong className={cn(classes.courseLabel, classes[`status_${color}`])}>{label}</strong>
                            <p>{interval}</p>
                          </div>
                        </Link>
                      );
                    })
                  ) : (
                    <p className={classes.notFound}>Ничего не найдено</p>
                  )}
                </div>
              </div>
            ) : (
              <EmptyCard
                image="images/courses.svg"
                description="У вас пока отсутствуют доступные для прохождения оценочные сессии"
              />
            )}
          </div>
        )}
      </Block>
      <Modal className={classes.modal} open={isOpenTourModal} onClose={handleCloseTourModal} >
        <ModalHeader >
          <CloseIcon onClick={handleCloseTourModal} width={28} height={28} className={classes.closeIcon} />
          <span className={classes.modalTitle}>Вы хотите пройти демонстрационную оценочную сессию?</span>
        </ModalHeader>
        <ModalFooter >
          <Button
            style={{
              backgroundColor: '#EFF8FE',
              color: '#0071CE',
            }}
            onClick={handleCloseTourModal}
            size="sm"
            variantStyle="standard"
          >
            Нет
          </Button>
          <Button
            onClick={handleStartTour}
            size="sm"
          >
            Да
          </Button>
        </ModalFooter>
      </Modal>
    </Page>
  );
});
