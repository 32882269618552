/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FC, useEffect, useMemo } from 'react';
import { useTour } from '@reactour/tour';
import { DisplayResults } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Modal, ModalHeader } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { CloseIcon } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/icons/close';
import { useModalState } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/modal/modal.hook';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { Tab, TabPanel, Tabs, useTabs } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/tabs';
import { observer } from 'mobx-react';
import { GRID_COMPETENCY_HEADER_LABELS, GRID_SPHERE_HEADER_LABELS, LEVEL_RESULT_INFO_BY_GRADE } from '../../../../../constants/testing-result-modal.constants';
import { TStage } from '../../../../../models/stores/builder.course.store';
import { ChartIcon } from '../../../../components/icons/chart';
import { useStyles } from '../../../../components/testing-result-modal/use-styles';
import { MODAL_MOCK_DATA } from '../../mock-data';
import { FINISH_STEP } from '../../steps';
import { ResultGrid } from './result-grid';
import { ResultRadarChart } from './result-radar-chart';

interface ITestingResultModalProps {
  stage: TStage;
}

const correctAnswers = [
  {
    'question-id': '644123b698abfbe1956ed6e6',
    'user-answer-id': '644123b60d87cb2f18697091',
    isCorrect: false,
  },
  {
    'question-id': '644122e898abfbe1956ed6e1',
    'user-answer-id': {
      0: '644122e80d87cb2f18696d0b',
      1: '644122e80d87cb2f18696d0d',
      2: '644122e80d87cb2f18696d0c',
      3: '644122e80d87cb2f18696d0a',
      4: '644122e80d87cb2f18696d0e',
    },
    isCorrect: false,
  },
  {
    'question-id': '64412c2698abfbe1956ed721',
    'user-answer-id': [
      '64412c260d87cb2f18698f73',
      '64412c260d87cb2f18698f74',
      '64412c260d87cb2f18698f75',
    ],
    isCorrect: false,
  },
  {
    'question-id': '65c5fb31989f3c33116fe11f',
    'user-answer-id': '65c5fb31989f3c33116fe121',
    isCorrect: false,
  },
  {
    'question-id': '644134df98abfbe1956ed753',
    'user-answer-id': {
      1: 'mlkmlm',
      2: 'mlkmlm',
    },
    isCorrect: false,
  },
];

export const TestingResultModal: FC<ITestingResultModalProps> = observer((_stage) => {
  const classes = useStyles();
  const modal = useModalState();
  const userTestData = sessionStorage.getItem('userTest');
  const answersData = userTestData !== undefined ? JSON.parse(userTestData) : undefined;
  const { currentStep, steps, setCurrentStep, setSteps } = useTour();

  const compareAnswers = (userAnswerId, correctAnswerId, type): boolean => {
    if (type === 'one' || type === 'case') {
      return userAnswerId === correctAnswerId;
    }
    if (type === 'matrix') {
      const userAnswerEntries = Object.entries(userAnswerId);
      const correctAnswerEntries = Object.entries(correctAnswerId);
      return JSON.stringify(userAnswerEntries) === JSON.stringify(correctAnswerEntries);
    }
    if (type === 'tree') {
      const userAnswerEntries = Object.entries(userAnswerId).sort();
      const correctAnswerEntries = Object.entries(correctAnswerId).sort();
      return JSON.stringify(userAnswerEntries) === JSON.stringify(correctAnswerEntries);
    }
    if (type === 'plural') {
      return JSON.stringify(userAnswerId) === JSON.stringify(correctAnswerId);
    }
    return false;
  };

  const handleOpen = () => {
    modal.open();
    setCurrentStep(2);
  };

  const handleClose = () => {
    if (steps.length - 1 <= currentStep) {
      setCurrentStep(0);
      setSteps(FINISH_STEP);
      tabs.onChange('competencies');

      modal.close();
    }
  };

  const updateCorrectAnswers = useMemo(() => {
    const updatedCorrectAnswers = correctAnswers.map((correctAnswer) => {
      const question = answersData && answersData.questions ? answersData.questions.find((q) => q['question-id'] === correctAnswer['question-id']) : null;
      if (question) {
        const isCorrect = compareAnswers(question['user-answer-id'], correctAnswer['user-answer-id'], question.type);
        return { ...correctAnswer, isCorrect };
      }
      return correctAnswer;
    });
    return updatedCorrectAnswers;
  }, []);

  // eslint-disable-next-line consistent-return
  const getLevel = (updatedCorrectAnswers): string => {
    if (!answersData) {
      return LEVEL_RESULT_INFO_BY_GRADE.normal_level.levelLabel;
    }
    const totalQuestions = updatedCorrectAnswers.length;
    const trueCount = updatedCorrectAnswers.filter((answer) => answer.isCorrect === true).length;
    const accuracy = (trueCount / totalQuestions) * 100;

    if (accuracy >= 88) {
      return LEVEL_RESULT_INFO_BY_GRADE.advanced_level.levelLabel;
    }
    if (accuracy >= 44) {
      return LEVEL_RESULT_INFO_BY_GRADE.expert_level.levelLabel;
    }
    if (accuracy >= 18) {
      return LEVEL_RESULT_INFO_BY_GRADE.normal_level.levelLabel;
    }
    if (accuracy >= 0) {
      return LEVEL_RESULT_INFO_BY_GRADE.normal_level.levelLabel;
    }
  };

  const level = getLevel(updateCorrectAnswers);

  const display_results = DisplayResults.competency;
  const displayOnlyByCompetency = display_results === DisplayResults.competency;
  const tabs = useTabs(displayOnlyByCompetency ? 'competencies' : '');

  useEffect(() => {
    if (tabs.value === 'diagram') {
      setCurrentStep(5);
    }
  }, [tabs.value]);

  useEffect(() => {
    if (currentStep < 2) {
      tabs.onChange('competencies');
      modal.close();
    }

    if (currentStep === 4 && tabs.value === 'diagram') {
      setCurrentStep(3);
      tabs.onChange('competencies');
    }
  }, [currentStep]);


  return (
    <>
      {MODAL_MOCK_DATA.map((data) => (
        <>
          <Paper data-tour="main-page-answer-step-1" data-tour-disabled="main-page-answer-step-1" className={classes.result} onClick={handleOpen}>
            <ChartIcon />
            <span>Смотреть результаты</span>
          </Paper>
          <Modal data-tour="main-page-answer-step-2" open={modal.isOpen} size="lg" className={classes.modal} color="blue">
            <ModalHeader>
              <h2 className={classes.title}>Результаты прохождения оценочной сессии</h2>
              <p className={classes.subtitle}>{data?.courseTitle}</p>
              <CloseIcon
                width={25}
                height={25}
                className={classes.closeIcon}
                data-tour="main-page-answer-step-5"
                onClick={handleClose}
              />
            </ModalHeader>
            <Tabs value={tabs.value} onChange={tabs.onChange} className={classes.tabs}>
              {displayOnlyByCompetency ? <Tab label="Компетенции" value="competencies" /> : <></>}
              <Tab data-tour="main-page-answer-step-3" label="Диаграмма" value="diagram" />
            </Tabs>

            <div data-tour="main-page-answer-step-4">
              <TabPanel value="competencies" current={tabs.value} className={classes.tab}>
                <ResultGrid data={data.competencies} columns={GRID_COMPETENCY_HEADER_LABELS} level={level} />
              </TabPanel>

              <TabPanel value="spheres" current={''} className={classes.tab}>
                <ResultGrid data={data.spheres} columns={GRID_SPHERE_HEADER_LABELS} level={level} />
              </TabPanel>

              <TabPanel value="diagram" current={tabs.value} className={classes.tab} >
                <ResultRadarChart charts={data.charts} stages={data.stages} displayResults={display_results} level={level} />
                {data.charts.map((chart) => (
                  <div key={chart.id} className={classes.bold}>{`${data.charts[0].key}. ${data.charts[0].title}`}</div>
                ))}
              </TabPanel>
            </div>
          </Modal>
        </>
      ))}
    </>
  );
});
