import React, { Fragment, ReactElement, ReactNode } from 'react';
import { ITesterCertificate, StatusType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Badge, Table } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import cn from 'classnames';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { TABLE_COLUMNS_NAMES, TABLE_ROW_NAME } from '../../../constants/certificates.constants';
import { useAppStore } from '../../../stores/context.store';
import { Block } from '../../components/block';
import { EmptyCard } from '../../components/empty-card';
import { DownloadIcon } from '../../components/icons/download';
import { QRCodeModal } from '../../components/qr-code-modal';
import { useStyles } from './use-styles';

type TTableRow = 'course_title' | 'created_at' | 'status';

export const CertificatePage: React.FC = observer(() => {
  const classes = useStyles();
  const appStore = useAppStore();
  const { data, loading, error } = appStore.certificates;
  const { s3Token } = appStore.users.auth.data;

  const headerClasses = {
    course_title: classes.tableTitleSize,
    created_at: classes.tableDateSize,
    status: classes.tableStatusSize,
  };

  const headerRowRender = ({ name }: { name: TTableRow }): ReactNode => (
    <div className={cn(classes.tableHeaderCols, headerClasses[name])}>{TABLE_ROW_NAME[name]}</div>
  );

  const downloadCert = (url: string): void => {
    const downloadUrl = new URL(url);
    downloadUrl.searchParams.set('s3token', s3Token);
    window.open(downloadUrl);
  };

  const rowRender = (column: ITesterCertificate, { className }): ReactNode => {
    const { course_title, end_date, status, _id, url } = column;
    const isReady = status === StatusType.Ready;
    return (
      <div key={_id} className={className}>
        <div className={cn(classes.tableBodyCols, classes.tableTitleSize)}>
          {course_title}
          <div onClick={(): void => downloadCert(url)} className={cn(classes.mobile, classes.download)}>
            <DownloadIcon />
          </div>
        </div>
        <div className={cn(classes.tableBodyCols, classes.tableDateSize)}>
          <div className={cn(classes.mobile, classes.secondaryText)}>{TABLE_ROW_NAME.created_at}: </div>
          {dayjs(end_date).format('DD.MM.YYYY')}
        </div>
        <div className={cn(classes.tableBodyCols, classes.tableStatusSize)}>
          {isReady ? (
            <Badge color={'success'}>Готов для скачивания</Badge>
          ) : (
            <Badge color={'warning'}>Ожидайте генерацию сертификата</Badge>
          )}
        </div>
        <div
          onClick={(): void => downloadCert(url)}
          className={cn(classes.download, classes.desktop, {
            [classes.disabled]: !isReady,
          })}
        >
          <DownloadIcon />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <Block data={data} loading={loading} error={error}>
        {(certificates): ReactElement => (
          <div className={classes.root}>
            <div className={classes.certificates}>
              <h1 className={classes.title}>Сертификаты</h1>

              <div className={classes.certificatesInfo}>
                <QRCodeModal />
                <p className={classes.count}>Всего сертификатов: {certificates.length}</p>
              </div>
            </div>
            {!!certificates?.length ? (
              <Table
                className={cn(classes.table, 'scrollbar')}
                data={certificates}
                columns={TABLE_COLUMNS_NAMES}
                headerRowRender={headerRowRender}
                rowRender={rowRender}
              />
            ) : (
              <EmptyCard
                image="/images/certificates.svg"
                description={
                  <span>
                    В этом разделе появятся сертификаты,
                    <br /> когда вы завершите оценку
                  </span>
                }
              />
            )}
          </div>
        )}
      </Block>
    </Fragment>
  );
});
